import { useState } from 'react';

import Logo from './Logo';
import EmailSignInForm from './EmailSignInForm';

interface Props {
  prompt?: React.ReactNode,
  creationId?: string,
  templateId?: string,
}

function SignIn(props: Props) {

  const [emailInputValue, setEmailInputValue] = useState<string>("");
  const [passwordInputValue, setPasswordInputValue] = useState<string>("");
  const [emailSentTo, setEmailSentTo] = useState<string | undefined>();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isRequestingPassword, setIsRequestingPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | undefined>();

  return (
    <div>
      <div className="modal-background" />
      <div className={`sign-in-container ${props.prompt ? 'with-custom-prompt' : ''}`}>
        <Logo onClick={() => window.location.href = "/"} />
        <EmailSignInForm
          creationId={props.creationId}
          templateId={props.templateId}
          emailInputValue={emailInputValue}
          onUpdateEmailInputValue={setEmailInputValue}
          passwordInputValue={passwordInputValue}
          onUpdatePasswordInputValue={setPasswordInputValue}
          emailSentTo={emailSentTo}
          onUpdateEmailSentTo={setEmailSentTo}
          errorMessage={errorMessage}
          onUpdateErrorMessage={setErrorMessage}
          isSending={isSending}
          onUpdateIsSending={setIsSending}
          isRequestingPassword={isRequestingPassword}
          onUpdateIsRequestingPassword={setIsRequestingPassword}
          prompt="Sign up or sign in:"
        />
      </div>
    </div>
  );

}

export default SignIn