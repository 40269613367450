import * as EmailValidator from 'email-validator';

import ensureFirebase from '../support/ensureFirebase';
const firebaseApp = ensureFirebase();
import { ActionCodeSettings, fetchSignInMethodsForEmail, getAuth, sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailAndPassword } from "firebase/auth";
const auth = getAuth(firebaseApp);

export interface EmailSignInProps {
  creationId?: string,
  templateId?: string,
  email: string | undefined,
  onSetErrorMessage: (msg: string | undefined) => void,
  onSetIsSending: (value: boolean) => void,
  onSetEmailSentTo: (email: string) => void,
  onSend?: () => void,
}

function destinationPath(props: EmailSignInProps | PasswordSignInProps): string {
  if (props.templateId) {
    return `/new?template=${props.templateId}`;
  } else if (props.creationId) {
    return `/c/${props.creationId}`;
  } else {
    return '/creations';
  }
}

export async function signin(props: EmailSignInProps) {

  if (props.email) {
    if (EmailValidator.validate(props.email)) {
      props.onSetErrorMessage(undefined);
      props.onSetIsSending(true);
      window.localStorage.setItem('emailForSignIn', props.email);
      const destinationUrl = `${window.location.origin}${destinationPath(props)}`;
      const actionUrlSettings: ActionCodeSettings = {
        url: destinationUrl,
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, props.email, actionUrlSettings);
      if (props.onSend) props.onSend();
      props.onSetIsSending(false);
      props.onSetEmailSentTo(props.email);
    } else {
      props.onSetErrorMessage("We need a valid email address to send you things 😛")
    }
  } else {
    props.onSetErrorMessage("We need an email address to send you things 😛");
  }

}

export interface PasswordSignInProps {
  creationId?: string,
  templateId?: string,
  email: string | undefined,
  password: string | undefined,
  onSetIsSending?: (value: boolean) => void,
  onSetErrorMessage: (msg: React.ReactNode | undefined) => void,
  onSuccess?: () => void,
}

export async function sendPasswordReset(email: string, onSetErrorMessage: (msg: React.ReactNode | undefined) => void) {
  try {
    await sendPasswordResetEmail(auth, email);
    onSetErrorMessage("We sent you an email with a link to reset your password. Check your spam folder if you don't see it in your inbox.");
  } catch (err: any) {
    onSetErrorMessage(err.message);
  }
}

export async function signinWithPassword(props: PasswordSignInProps) {
  const { email } = props;
  if (!email || email.length === 0) {
    props.onSetErrorMessage("We need an email address to sign you in 😛");
  } else if (!EmailValidator.validate(email)) {
    props.onSetErrorMessage("We need a valid email address to sign you in 😛")
  } else if (!props.password || props.password.length === 0) {
    props.onSetErrorMessage("Don't forget your password 😛");
  }
  else {
    props.onSetErrorMessage(undefined);
    window.localStorage.setItem('emailForSignIn', email);
    try {
      if (props.onSetIsSending) props.onSetIsSending(true);
      await signInWithEmailAndPassword(auth, email, props.password);
      if (props.onSetIsSending) props.onSetIsSending(false);
      const destinationUrl = `${window.location.origin}${destinationPath(props)}`;
      window.location.href = destinationUrl;
    } catch (err: any) {
      if (err.code === 'auth/wrong-password') {
        props.onSetErrorMessage(<span>Email and password don't match. Please try again or <a onClick={() => sendPasswordReset(email, props.onSetErrorMessage)}>reset your password</a>.</span>);
      } else {
        props.onSetErrorMessage(err.message);
      }
    }
    if (props.onSuccess) props.onSuccess();
  }
}

export async function signinMethodsForEmail(email: string) {
  return await fetchSignInMethodsForEmail(auth, email);
}