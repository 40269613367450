import { useEffect } from 'react';
import {
  Button,
  Input,
} from 'semantic-ui-react';
import { signin, signinWithPassword, signinMethodsForEmail } from '../support/SignIn';

interface Props {
  prompt?: React.ReactNode,
  creationId?: string,
  templateId?: string,
  emailInputValue: string,
  onUpdateEmailInputValue: (v: string) => void,
  emailSentTo: string | undefined,
  onUpdateEmailSentTo: (v: string | undefined) => void,
  isSending: boolean,
  onUpdateIsSending: (v: boolean) => void,
  passwordInputValue: string,
  onUpdatePasswordInputValue: (v: string) => void,
  isRequestingPassword: boolean,
  onUpdateIsRequestingPassword: (v: boolean) => void,
  errorMessage: React.ReactNode | undefined,
  onUpdateErrorMessage: (m: React.ReactNode | undefined) => void,
  onSend?: () => void,
}

function EmailSignInForm(props: Props) {

  const {
    creationId,
    emailInputValue,
    onUpdateEmailInputValue,
    emailSentTo,
    onUpdateEmailSentTo,
    errorMessage,
    onUpdateErrorMessage,
    isSending,
    onUpdateIsSending,
    passwordInputValue,
    onUpdatePasswordInputValue,
    isRequestingPassword,
    onUpdateIsRequestingPassword,
    templateId,
  } = props;

  useEffect(() => {
    if (emailInputValue.length === 0) {
      const stored = window.localStorage.getItem('emailForSignIn') || "";
      onUpdateEmailInputValue(stored);
    }
  }, []);

  async function send() {
    if (isRequestingPassword) {
      await signinWithPassword({
        creationId,
        templateId,
        email: emailInputValue,
        password: passwordInputValue,
        onSetErrorMessage: onUpdateErrorMessage,
        onSuccess: props.onSend,
      });
    } else {
      const methods = await signinMethodsForEmail(emailInputValue);
      if (methods.includes('password')) {
        onUpdateIsRequestingPassword(true);
      } else {
        await signin({
          creationId,
          templateId,
          email: emailInputValue,
          onSetErrorMessage: onUpdateErrorMessage,
          onSetEmailSentTo: onUpdateEmailSentTo,
          onSetIsSending: onUpdateIsSending,
          onSend: props.onSend,
        });
      }
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      send();
    } else {
      onUpdateErrorMessage(undefined);
    }
  }

  function renderPrompt() {
    return props.prompt || 'Enter your email address to sign in:';
  }

  function renderTerms() {
    if (errorMessage) {
      return null;
    } else if (emailInputValue.length > 0) {
      return (
        <div className="terms">By signing in you agree to our <a target="_blank" href="/tos">Terms of Service</a> and <a target="_blank" href="/privacy">Privacy Policy</a>.</div>
      );
    } else {
      return (
        <div className="terms">We'll send you a 🪄 magic link to sign in right away. No password needed.</div>
      );
    }
  }

  function renderPasswordInput() {
    if (isRequestingPassword) {
      return (
        <Input
          type="password"
          autoComplete="on"
          size="huge"
          disabled={isSending}
          onChange={(event) => onUpdatePasswordInputValue(event.target.value)}
          onKeyDown={handleKeyDown}
          value={passwordInputValue}
          autoFocus
        />
      )
    } else {
      return null;
    }
  }

  if (emailSentTo === undefined) {
    return (
      <div className="action">
        <div className="terms">{renderPrompt()}</div>
        <div className={`signin-link-form ${errorMessage ? "error" : ""} ${isRequestingPassword ? "password" : ""}`}>
          <Input
            type="email"
            autoComplete="on"
            size="huge"
            disabled={isSending}
            onChange={(event) => onUpdateEmailInputValue(event.target.value)}
            onKeyDown={handleKeyDown}
            value={emailInputValue}
            placeholder="you@example.com"
            autoFocus
          />
          {renderPasswordInput()}
          <Button
            onClick={send}
            disabled={isSending}
            size="huge"
            icon={isSending ? "spinner" : "right arrow"}
            content={isRequestingPassword ? "Sign in" : undefined}
            labelPosition={isRequestingPassword ? "right" : undefined}
          />
        </div>
        {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
        {renderTerms()}
        {/* <div className="signin">
            <span>Already have an account?</span>&nbsp;<a href="/creations" >
              <Icon name="user circle" />
              <span>Sign in</span>
            </a>
          </div> */}

      </div>
    );
  } else {
    return (
      <div className="action">
        <div className="signin-link-sent">
          <p>We've sent a link to <span className="email">{emailSentTo}</span> that will let you sign in without a password.</p>
          <p>If you don't see it within a few minutes, check your spam folder or <a onClick={() => onUpdateEmailSentTo(undefined)}>send&nbsp;another&nbsp;one</a>.</p>
        </div>
      </div>
    )

  }
}

export default EmailSignInForm;